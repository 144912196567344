@font-face {
  font-family: "Montserrat-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat-Medium";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Montserrat-Medium.ttf");
}

@font-face {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/Montserrat-Bold.ttf");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/Montserrat-SemiBold.ttf");
}

:root {
  --regular-font: Montserrat-Regular, sans-serif;
  --medium-font: Montserrat-Medium, sans-serif;
  --bold-font: Montserrat-Bold, sans-serif;
  --semibold-font: Montserrat-SemiBold, sans-serif;
  --color-basic: #454545;
  --color-basic-border: #434343;
  --color-btn-border: #9d9d9d;
  --color-theme: #007cba;
  --color-dark: #333333;
  --color-light-black: #333333;
  --color-black: #000000;
  --color-white: #ffffff;
  --color-light: #e7f1f1;
  --color-cyan: #00b1b9;
  --color-select-label: #636466;
  --color-select-border: #9d9d9d;
  --color-border: #c4c4c4;
  --color-bg-light: #e5e5e5;
  --color-bg-secondary: #f4f3f3;
  --color-gray: #666666;
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-background: #454545;
    --font-color: #ffffff;
  }
}

input[type="*"]::-webkit-input-placeholder {
  color: var(--color-black);
  opacity: 1;
}
input[type="*"]::-moz-placeholder {
  color: var(--color-black);
  opacity: 1;
}
input[type="*"]:-ms-input-placeholder {
  color: var(--color-black);
  opacity: 1;
}
input[type="*"]::-ms-input-placeholder {
  color: var(--color-black);
  opacity: 1;
}
input[type="*"]::placeholder {
  color: var(--color-black);
  opacity: 1;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

html,
body {
  line-height: 1;
  margin: 0;
  padding: 0;
  background-color: var(--color-white);
  font-family: var(--regular-font);
  color: var(--color-dark);
}

b,
strong {
  line-height: 1.25rem;
}

.btn {
  border-radius: 0;
  /* font-family: var(--bold-font);
    font-size: 18px; */
  padding: 1.04rem 3rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

.btn.btn-primary {
  background-color: var(--color-theme);
  border-color: var(--color-theme);
}

button:focus {
  outline: none;
}

.img-fluid {
  max-width: 100%;
  height: auto;
  width: 100%;
}
a {
  color: #017cba;
}
a:hover {
  color: #017cba;
}
