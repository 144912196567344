.leadership-img {
  width: 100%;
}

.leadership-card {
  margin-top: 65px;
  font-family: Montserrat-Medium;
  font-weight: 500;
}

.leadership-name {
  margin-top: 22px;
  color: #007cba;
  font-size: 24px;
}

.leadership-role {
  color: #333333;
  font-size: 18px;
  margin-top: 3px;
}

.leadership-members-container {
  margin-top: 52px;
  margin-bottom: 260px;
}

.leadership-img-wrapper {
  position: relative;
  overflow: hidden;
}

.leadership-img-gradient {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.75) 100%
  );
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.leadership-img-wrapper:hover .leadership-img-gradient {
  opacity: 1;
}

.profile-button {
  pointer-events: auto;
  margin-bottom: 15%;
  background-color: transparent;
  border: 1px solid white;
  padding: 10px 40px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  color: white;
}

.leadership-members-details-container {
  min-height: 65vh;
  margin-bottom: 250px;
}
.leadership-details-name {
  font-weight: 400;
  color: #007cba;
  font-size: 36px;
  line-height: 26px;
}

.leadership-details-role {
  color: #333333;
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;
  margin-top: 8px;
}

.leadership-details-bio {
  color: #333333;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
}

.profile-close-button{
  border: none;
  background-color: transparent;
}

.leadership-details-content-row{
  margin-top: 73px;
}

@media only screen and (min-width: 768px) {
  .leadership-details-image-wrapper{
    padding-right: 69px;
  }
}

@media only screen and (max-width: 767px) {
  .leadership-details-name{
    margin-top: 20px !important;
  }
}
