@import "./base.css";

/*error message css*/

.error-msg {
  color: red;
  text-align: left;
  font-size: 12px;
  padding: 4px;
}

.input-invalid {
  border: 1.5px solid red !important;
}

.marker {
  width: 25px;
  height: 25px;
}

.hover-marker {
  z-index: 1;
  position: relative;
}

.footer-contactus .email a,
.footer-contactus .phone a {
  text-decoration: none;
  color: var(--color-border);
}

.footer-contactus .email a :hover {
  color: var(--color-border) !important;
}

/* / Main Header / */

.main-banner {
  position: relative;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.welcome {
  color: var(--color-white);
  text-transform: uppercase;
  font-size: 4vw;
  font-family: var(--bold-font);
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  /* top: 518px; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  z-index: 99;
  /* height: fit-content; */
}

.head-text.d-none {
  display: none;
}

#head-text {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 20px;
}

#location-menu select {
  width: 100%;
  border: none;
  background-color: var(--color-light);
  font-weight: 500;
}

#apartment-menu {
  background-color: var(--color-light);
  padding: 10px;
}

#apartment-menu select {
  width: 100%;
  border: none;
  background-color: var(--color-light);
  font-weight: 500;
}

#checkbox-menu {
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

/* / Banner Image / */

.banner-wrap {
  width: 1342px;
  min-height: 798px;
  background-position: top;
  background-repeat: no-repeat;
  position: absolute;
}

.ania-banner {
  padding: 2rem;
}

.ania-services {
  background-color: #519cca;
  padding: 2rem 1rem;
}

.ania-story {
  padding: 5rem 0rem;
}

.ania-story-1 {
  position: relative;
  min-height: 800px;
}

.section-title {
  font-family: var(--bold-font);
  font-size: 30px;
  color: white;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 10px;
}
.fproperty {
  color: #454545 !important;
}

/* / Services section / */
.services-row-first,
.services-row-second {
  display: table;
  width: 100%;
  height: 100%;
}

.services-block-wrapper {
  padding: 15px;
  display: table-cell;
  height: 100%;
  width: 40%;
  -webkit-transition: width 500ms;
  -o-transition: width 500ms;
  transition: width 500ms;
  padding-top: 0;
  padding-bottom: 30px;
}

.services-block {
  padding: 30px;
  border: 1px solid var(--color-basic-border) !important;
  background-color: var(--color-white);
}

.services-row-first:hover .services-block-wrapper,
.services-row-second:hover .services-block-wrapper {
  width: 40%;
}

.services-row-first:hover .services-block-wrapper:hover,
.services-row-second:hover .services-block-wrapper:hover {
  width: 60%;
}

.services-block-wrapper:first-child {
  padding-left: 0;
}

.services-block-wrapper:last-child {
  padding-right: 0;
}

/* .services-row-first > .services-block-wrapper:last-child:hover {
  flex-basis: 30%;
} */

.services-img {
  width: 83px;
  height: 83px;
}

.services-title {
  font-size: 24px;
  font-weight: 700;
  margin: 8px 0 10px;
  margin-left: 6px;
  color: var(--color-basic);
}

.services-description {
  font-size: 18px;
  line-height: normal;
  min-height: 60px;
  margin-left: 6px;
  margin-bottom: 1.2rem;
  color: var(--color-basic) !important;
}

.services-block-wrapper .services-learnmore {
  margin-left: 6px;
  font-weight: 700;
  color: var(--color-btn-border);
  border: 1px solid var(--color-white);
  border-radius: 0.25rem;
  padding: 0.375rem 0;
  font-size: 17px;
  -webkit-transition: ease 0.3s all;
  -o-transition: ease 0.3s all;
  transition: ease 0.3s all;
}

.services-block-wrapper:hover .services-learnmore {
  border: 1px solid var(--color-btn-border);
  border-radius: 0 !important;
  color: var(--color-btn-border);
  padding: 0.375rem 0.75rem;
  text-decoration: none;
}

.story-btn {
  color: var(--color-btn-border);
  border: 1px solid var(--color-btn-border);
  padding: 8px !important;
}

.img-left {
  width: 650px;
  height: auto;
}

.content-right {
  position: absolute;
  right: 12%;
  top: 10%;
  width: 270px;
}

.content-right .section-title {
  font-family: var(--bold-font);
  font-size: 30px;
  color: var(--font-color-basic);
  text-align: left;
  margin-bottom: 10px;
  margin-top: 10px;
}

.content-right h3,
.content-right button {
  font-weight: bold;
}

.img-right {
  width: 650px;
  height: auto;
  position: absolute;
  top: 40%;
  right: 0;
  border: 10px solid var(--color-white);
}

.ania-featured-properties {
  padding-bottom: 5rem;
}

.ania-footer {
  background-color: var(--color-black);
  color: var(--color-border);
  padding: 2.813rem 0 3.5rem;
}

.featured-block {
  margin-bottom: 30px;
  position: relative;
}

.featured-caption {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px;
  overflow: hidden;
}

.featured-caption-text {
  color: var(--color-white);
  margin: 0;
  font-size: 17px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ania-quicklinks {
  list-style-type: none;
  padding: 0;
}

.ania-quicklinks li {
  margin-bottom: 18px;
}

.ania-quicklinks li a {
  color: var(--color-border);
  text-decoration: none;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 14px;
}

.ania-quicklinks li.text-transform-auto {
  text-transform: unset;
}

.footer-copyright {
  padding: 0;
  margin-top: 3.125rem;
}

.ania-footer .title {
  font-family: var(--bold-font);
  font-size: 16px;
  margin-bottom: 22px;
}

.ania-footer .email,
.ania-footer .phone,
.ania-footer .address {
  margin-bottom: 0;
  line-height: 21.94px;
  font-size: 16px;
}

.ania-footer .address {
  margin-top: 22px;
}

.social-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style-type: none;
}

.social-icons li {
  margin: 0 4px;
}

.ania-footer .copyright {
  font-size: 12px;
  text-align: left;
  line-height: normal;
  margin-bottom: 0;
}

p.office-address {
  margin-bottom: 5px;
  font-size: 20px;
  line-height: 1.371rem;
}

.properties .ania-content {
  padding: 2rem 0;
}

.properties .properties-card {
  margin-bottom: 4rem;
  /* width: 619px; */
}
.properties-card img {
  cursor: pointer;
}
/* .properties .properties-card img{
  width: 100%;
  max-width: 619px;
  height: 381px;
} */

.properties .properties-card .propterties-card-body .location {
  letter-spacing: 0.1rem;
}

.properties .properties-card .propterties-card-body .location .city {
  font-family: var(--bold-font);
}

.propterties-card-body .sub-location .city {
  font-family: var(--bold-font);
}

.properties .properties-card .propterties-card-body .address {
  font-family: var(--regular-font);
  font-size: 46px;
  text-transform: capitalize;
}

.properties-detail .properties-card .propterties-card-body .sub-address {
  font-size: 30px;
  text-transform: capitalize;
  line-height: 44px;
}

.properties .map-title {
  letter-spacing: 0.1rem;
  font-size: 22px;
  font-family: var(--medium-font);
}

.properties .embed-responsive {
  max-height: 1147px;
  height: 1147px;
}

.properties-detail .embed-responsive {
  max-height: 658px;
  height: 658px;
}

.find-a-unit .properties-card {
  margin-bottom: 4rem;
}

.find-a-unit .properties-card .properties-card-body {
  padding: 4rem;
}

.find-a-unit .embed-responsive {
  height: 100%;
}

.find-a-unit .properties-card .propterties-card-body .location .city {
  font-family: var(--bold-font);
}

.find-a-unit .properties-card .propterties-card-body {
  border-bottom: 2px solid var(--color-basic);
  border-left: 2px solid var(--color-basic);
  border-right: 2px solid var(--color-basic);
  padding: 0.5rem 1rem;
}

.btn.btn-outline-none {
  border-radius: 0;
  font-family: var(--bold-font);
  font-size: 16px;
  padding: 0.5rem 1rem;
  color: var(--color-basic);
}

.find-a-unit .price-and-availability {
  border-bottom: 2px solid var(--color-basic);
}

.find-a-unit .price-and-availability .price .net-price,
.find-a-unit .price-and-availability .availability .net-availability {
  font-family: var(--bold-font);
  color: var(--color-basic);
}

.find-a-unit .type {
  font-family: var(--regular-font);
  color: var(--color-basic);
  font-size: 20px;
}

.find-a-unit .unit-card-img {
  height: auto;
  max-height: 397px;
  object-fit: cover;
  object-position: center;
}

.properties-card {
  position: relative;
}

.find-a-unit .properties-card .property-badge {
  position: absolute;
  right: 0;
  padding: 8px 12px;
  text-transform: capitalize;
  font-size: 13px;
  color: var(--color-white);
  font-family: var(--semibold-font);
}

.find-a-unit .properties-card .property-badge.nr {
  background-color: var(--color-theme);
}

.find-a-unit .properties-card .property-badge.cs {
  background-color: var(--color-cyan);
}

.user-details h6 {
  color: var(--color-theme);
  font: 500 18px var(--medium-font);
  margin-top: 2px;
  margin-bottom: 2px;
}

.page-heading {
  font-weight: 400;
  color: var(--color-theme);
  font-size: 30px;
  margin: 2rem 0;
}

.leadership-content {
  padding: 2.5rem 0;
  display: -ms-grid;
  display: grid;
  gap: 34px;
}

.user {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 123px 10px 1fr;
  grid-template-columns: 123px 1fr;
  gap: 10px;
  -ms-grid-rows: auto 10px auto;
  grid-template-areas: "pic details" "description description";
}

.user-pic {
  width: 123px;
  height: 133px;
  background-color: #c4c4c4;
}

.user-pic img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.user-description {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  grid-row: 3;
  grid-column: 1;
  grid-area: description;
  margin-bottom: 0;
  margin-top: 8px;
  line-height: 1.375;
}

.contact-us .ania-content .main-content {
  padding-top: 0px;
  padding-bottom: 0px;
}

.border-bottom {
  border-bottom: 1px solid var(--color-border) !important;
}

/* .faqs-section-top .main-content :last-child {
  margin: 0;
}  */
.faqs-section-top {
  text-align: center;
}

.faqs-section-top .page-title {
  color: var(--color-theme);
  margin-bottom: 12px;
}

.faqs-section-top h6 {
  font-size: 20px;
  line-height: 27px;
}

.faq-ania-form {
  max-width: 746px;
  margin: 0 auto;
  margin-bottom: 32px;
}

.faq-ania-form .form-control {
  border-radius: 0px;
  border-color: #9d9d9d;
  padding: 0 22px;
  height: inherit;
}

.faq-ania-form .btn {
  font-family: var(--semibold-font);
  min-width: 225px;
  border-radius: 0px;
}

.faqs-listing {
  padding: 0.7rem 0 7rem;
}

.faqs-category-buttons .d-flex {
  padding: 0;
  flex-wrap: wrap;
}

.faqs-category-buttons .d-flex button {
  width: calc(25% - 20px);
  margin: 0 10px 10px;
  background: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #bebebe;
  color: var(--color-light-black);
  padding: 20px;
  align-items: center;
  font-family: var(--semibold-font);
}

.faqs-category-buttons .d-flex .icon {
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 auto 10px;
}

.faqs-category-buttons .d-flex .icon:hover {
  filter: invert(43%) sepia(93%) saturate(5092%) hue-rotate(180deg)
    brightness(90%) contrast(101%);
}

.faqs-category-buttons .d-flex button:hover {
  /* box-shadow: 0 0 10px rgb(0, 124, 186); */
  border: 1px solid rgb(0, 124, 186);
}

.faqs-listin-head {
  padding: 3rem 0;
}

.faqs-listin-head h2 {
  font-family: var(--medium-font);
}

.allfaqs {
  max-width: 920px;
  margin: 0 auto;
  padding-top: 15px;
}

.allfaqs .card {
  border: 0;
  background-color: transparent;
}

.allfaqs .card-header {
  background-color: transparent;
  padding: 0;
  border: 0;
}

.allfaqs .card-header .btn {
  font-size: 18px;
  font-family: Montserrat;
  font-weight: 600;
  color: var(--color-light-black);
  padding: 22px 80px 21px 25px;
  border: 0;
  border-bottom: 1px solid transparent;
  width: 100%;
  position: relative;
  text-align: left;
}

.allfaqs .card-header .btn.collapsed {
  border-bottom-color: #c4c4c4;
}

.allfaqs .card-header .btn::after {
  content: "";
  position: absolute;
  top: 28px;
  right: 0;
  width: 21px;
  height: 21px;
  background: url(../images/minus-icon.svg) no-repeat center;
  background-size: 100%;
}

.allfaqs .card-header .btn.collapsed:after {
  background-image: url(../images/plus-icon.svg);
}

.allfaqs .card-header .btn,
.allfaqs .card-header .btn:focus {
  box-shadow: none;
  outline: none;
}

.allfaqs .card-header .btn:hover {
  text-decoration: none;
}

.allfaqs .card-body {
  padding: 0 80px 25px 25px;
  border-bottom: 1px solid #c4c4c4;
}

.allfaqs .card-body p {
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 1rem;
}

.allfaqs .card-body p:last-child {
  margin-bottom: 0;
}

.ania-content .main-content {
  padding-top: 30px;
  padding-bottom: 30px;
}
.slick-slider {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.slick-next {
  right: 0 !important;
  z-index: 99;
}
.slick-prev {
  left: 0 !important;
  z-index: 99;
}
.slick-next , .slick-prev {
  background-color: #80808080 !important;
  height: 70px !important;
  width: 70px !important;
  border-radius: 100%;
}
.slick-next:hover , .slick-prev:hover{
  background-color: #808080ad !important;
}
.page-content-section {
  width: 100%;
  padding: 3.8rem 0;
}

.page-content-section h2 {
  margin-bottom: 3.1rem;
  color: var(--color-light-black);
  font-size: 34px;
}

.page-content-section h3 {
  margin: 3.1rem 0 0;
  color: var(--color-light-black);
  font-size: 24px;
  font-weight: 400;
  /* font-family: var(--semibold-font); */
}

.page-content-section h5 {
  margin: 1.6rem 0 0;
  color: var(--color-light-black);
  font-family: var(--semibold-font);
}

.page-content-section h6 {
  margin: 1.6rem 0 0;
  color: var(--color-light-black);
  font-family: var(--medium-font);
}

.page-content-section p {
  margin: 1.6rem 0 0;
  font-size: 16px;
  line-height: 26px;
  color: var(--color-basic);
}

.page-content-section a {
  color: var(--color-theme);
}

.page-content-section a:hover {
  text-decoration: underline;
}

.page-content-section strong {
  font-family: var(--semibold-font);
}

/* Leadership CSS */

.leadership-content {
  padding: 2.5rem 0;
  display: -ms-grid;
  display: grid;
  gap: 34px;
}

.user {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 123px 10px 1fr;
  grid-template-columns: 123px 1fr;
  gap: 10px;
  -ms-grid-rows: auto 10px auto;
  grid-template-areas: "pic details" "description description";
}

.user-pic {
  width: 123px;
  height: 133px;
  background-color: var(--color-border);
}

.user-pic img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.user-description {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  grid-row: 3;
  grid-column: 1;
  grid-area: description;
  margin-bottom: 0;
  margin-top: 8px;
  line-height: 1.375;
}

/* Find a Unit Css */

.find-a-unit .properties-card {
  margin-bottom: 4rem;
}

.find-a-unit .properties-card .properties-card-body {
  padding: 4rem;
}

.find-a-unit .properties-card .propterties-card-body .location .city {
  font-family: var(--bold-font);
  font-size: 22px;
}

.find-a-unit .properties-card .propterties-card-body .location .address {
  font-family: var(--regular-font);
  font-size: 22px;
}

.find-a-unit .properties-card .propterties-card-body {
  border-bottom: 1px solid var(--color-select-border);
  border-left: 1px solid var(--color-select-border);
  border-right: 1px solid var(--color-select-border);
  padding: 0.5rem 1rem 0px;
  color: var(--color-select-label);
}

.absentImageThumb{
  border-top: 1px solid var(--color-select-border);
  border-left: 1px solid var(--color-select-border);
  border-right: 1px solid var(--color-select-border);
}

.find-a-unit .properties-card .propterties-card-body-top {
  border: 1px solid var(--color-select-border);
  padding: 0.5rem 1rem 0px;
  color: var(--color-select-label);
}

.find-a-unit .price-and-availability {
  border-bottom: 1px solid var(--color-select-border);
  padding-bottom: 19px;
}

.find-a-unit .price-and-availability .price .net-price,
.find-a-unit .price-and-availability .availability .net-availability {
  font-family: var(--bold-font);
  font-size: 22px;
  /* margin-bottom: 12px; */
  line-height: 29px;
}

.find-a-unit .price-and-availability .price .value,
.find-a-unit .price-and-availability .availability .value {
  font-family: var(--regular-font);
  font-size: 22px;
  /* margin-bottom: 12px; */
  line-height: 12px;
}

.find-a-unit .type {
  font-family: var(--regular-font);
  font-size: 34px;
  line-height: 44px;
}

.find-a-unit .properties-card .property-badge {
  position: absolute;
  right: 0;
  padding: 8px 12px;
  text-transform: capitalize;
  font-size: 16px;
  color: var(--color-white);
  font-family: var(--semibold-font);
}

.find-a-unit .properties-card .property-badge.nr {
  background-color: var(--color-theme);
}

.find-a-unit .properties-card .property-badge.cs {
  background-color: var(--color-cyan);
}

.find-a-unit .propterties-card-body .location .city {
  font-family: var(--bold-font);
}

.find-a-unit .propterties-card-body .address {
  font-family: var(--regular-font);
  font-size: 34px;
  text-transform: capitalize;
}

.find-a-unit.detail .type {
  font-family: var(--regular-font);
  font-size: 42px;
}

.find-a-unit.detail .price-and-availability .price .net-price,
.find-a-unit.detail .price-and-availability .availability .net-availability {
  font-size: 16px;
  margin-bottom: 24px;
  min-width: 120px;
}

.featured-block .nearby-img {
  object-fit: contain;
  width: 100%;
  height: 327px;
}

.fixed-height {
  max-height: 776px;
  height: 100%;
  position: relative;
}

.fixed-height .text-capitalize {
  position: absolute;
  bottom: 0;
}

.privacy-page-list li {
  margin-top: 30px;
  font-size: 20px;
  font-weight: 600;
  list-style: lower-roman;
}

@media (min-width: 1441px) {
  .featured-block .nearby-img {
    width: 100%;
  }

  .custom-padding {
    /* padding-left: 8rem !important; */
    padding-left: 2rem !important;
  }
}

/* @media (min-width:1440px) {
  .find-a-unit .scroll-view {
    min-width: 848px;
  }

} */

@media (min-width: 990px) {
  /* .price-and-availability .availability p {
    margin-right:20px !important;
  } */

  .featured-block .nearby-img {
    height: 377px;
  }

  .ml-15 {
    margin-left: 15px;
  }
}

@media (min-width: 1230px) {
  .custom-padding {
    padding-left: 2rem !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1440px) {
  .services-description {
    font-size: 16px;
  }

  .services-title {
    font-size: 22px;
  }

  .services-block-wrapper .services-learnmore {
    font-size: 16px;
  }
}

.find-a-unit.detail .price-and-availability .price .value,
.find-a-unit.detail .price-and-availability .availability .value {
  font-family: var(--regular-font);
  font-size: 22px;
  margin-bottom: 50px;
}

.find-a-unit.detail .price-and-availability {
  margin-top: 35px;
}

.find-a-unit.detail .property-content h2 {
  text-align: center;
  font-size: 22px;
  font-family: var(--regular-font);
  margin-bottom: 2.5rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 100px;
}

.find-a-unit.detail
  .similar-apartments
  .properties-card
  .propterties-card-body
  .location
  .address {
  font-family: var(--regular-font);
  font-size: 18px;
}

.find-a-unit.detail
  .similar-apartments
  .properties-card
  .propterties-card-body
  .location
  .city {
  font-family: var(--bold-font);
  font-size: 18px;
}
.propterties-card-body .address {
  cursor: pointer;
}

.find-a-unit.detail .similar-apartments .type {
  font-family: var(--regular-font);
  font-size: 32px;
}

.find-a-unit.detail
  .similar-apartments
  .price-and-availability
  .price
  .net-price,
.find-a-unit.detail
  .similar-apartments
  .price-and-availability
  .availability
  .net-availability {
  font-size: 18px;
  margin-bottom: 24px;
  min-width: 120px;
}

.find-a-unit.detail .similar-apartments .price-and-availability .price .value,
.find-a-unit.detail
  .similar-apartments
  .price-and-availability
  .availability
  .value {
  margin-bottom: 40px;
}

.find-a-unit.detail .similar-apartments .btn.btn-outline-none {
  border-radius: 0;
  font-family: var(--bold-font);
  font-size: 20px;
  margin: 5px 0;
  padding: 0;
  color: var(--color-select-label);
}

.find-a-unit.detail .apply {
  margin-top: 96px;
  margin-bottom: 102px;
}

.find-a-unit.detail .slide {
  padding: 10px;
  height: auto;
  max-height: 800px;
  cursor: pointer;
}

.find-a-unit.detail .properties-card {
  margin-bottom: 1rem;
}

.find-a-unit .search-unit label {
  line-height: 17px;
  display: block;
  font-family: var(--bold-font);
  font-size: 14px;
  text-transform: capitalize;
  padding-left: 5px;
  margin-bottom: 0;
  color: var(--color-select-label);
}

.find-a-unit .search-unit .form-control {
  padding: 0;
  font-size: 18px;
  height: calc(1.2em + 0.75rem + 2px);
  box-shadow: none;
  color: var(--color-select-label);
}

.find-a-unit .search-unit .form-control .form-control-options {
  font-size: 16px !important;
  font-family: Montserrat;
}

/* .find-a-unit .search-unit .form-control.monthly-rent{
  padding: 0.375rem 0.75rem;
  font-size: 18px;
  height: calc(1.2em + 0.75rem + 2px);
  box-shadow: none;
  color: var(--color-select-label);
}  */

.find-a-unit .search-unit .monthly-rent {
  padding: 0.75rem 0.75rem;
  /* font-size: 18px; */
  box-shadow: none;
  color: var(--color-select-label);
  background: #fff;
  border: 1px solid #bbbbbb;
  height: 48px;
  margin-top: 11px;
  border-top: none;
  position: absolute;
  z-index: 9;
}

.find-a-unit .search-unit .search-option {
  border: 1px solid var(--color-select-border);
  padding: 8px 0px 0;
  position: relative;
}

/* .find-a-unit .search-box .MuiOutlinedInput-root{
  padding:8px 0px 0;
} */

/* .find-a-unit .fixed-card {
  width:2050px;
  margin-right: 16px;
} */

.search-option::parent {
  width: 100%;
}

.search-unit .col {
  margin-bottom: 15px;
  padding-right: 0px;
}

.search-unit .col:last-child {
  padding-right: 15px;
}

.location {
  /* overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; */
  padding: 20px 0 17px;
  line-height: 29px;
}

.search-unit .btn {
  padding: 0.95rem 3rem;
  display: flex;
  align-items: center;
}

.find-a-unit .search-unit .btn {
  font-weight: 600;
  padding: 18px;
  display: flex;
  align-items: center;
}

.btn.btn-outline-none {
  border-radius: 0;
  font-family: var(--bold-font);
  font-size: 18.5px;
  margin: 5px 0;
  padding: 0;
  color: var(--color-select-label);
}

.btn.btn-outline-none::after {
  position: relative;
  content: "";
  display: inline-block;
  width: 0.65em;
  height: 0.65em;
  border-right: 0.1em solid var(--color-select-label);
  border-top: 0.1em solid var(--color-select-label);
  transform: rotate(45deg);
  margin-right: 0.5em;
  left: 5px;
  top: -1px;
}

/* Find a Unit Details */
.find-a-unit.detail .type {
  font-family: var(--regular-font);
  font-size: 46px;
  line-height: 59px;
}

.find-a-unit.detail .price-and-availability .price .net-price,
.find-a-unit.detail .price-and-availability .availability .net-availability {
  font-size: 16px;
  margin-bottom: 24px;
  min-width: 120px;
}

.find-a-unit.detail .similar-apartments .price-and-availability .price .value,
.find-a-unit.detail
  .similar-apartments
  .price-and-availability
  .availability
  .value {
  font-family: var(--regular-font);
  font-size: 22px;
  line-height: 12px;
}

.find-a-unit.detail .price-and-availability .price .value,
.find-a-unit.detail .price-and-availability .availability .value {
  font-family: var(--regular-font);
  font-size: 34px;
  line-height: 44px;
  margin-bottom: 50px;
}

.availability {
  padding-left: 100px;
  /* padding-right: 1rem; */
}

.address-font {
  font-size: 16px;
  line-height: 22px;
}

.find-a-unit.detail .margin-top {
  padding-top: 36px;
  padding-bottom: 50px;
}

.find-a-unit.detail .price-and-availability {
  margin-top: 35px;
}

.find-a-unit.detail .price-and-availability .availability {
  padding-left: 100px;
  padding-right: 1rem;
}

.find-a-unit.detail .property-content h2 {
  text-align: center;
  font-size: 22px;
  font-family: var(--regular-font);
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

.find-a-unit.detail
  .similar-apartments
  .properties-card
  .propterties-card-body
  .location
  .address {
  font-family: var(--regular-font);
  font-size: 18px;
  line-height: 25px;
}

.find-a-unit.detail
  .similar-apartments
  .properties-card
  .propterties-card-body
  .location
  .city {
  font-family: var(--bold-font);
  font-size: 18px;
  line-height: 25px;
}

.find-a-unit.detail .similar-apartments .type {
  font-family: var(--regular-font);
  font-size: 34px;
  line-height: 44px;
}

.find-a-unit.detail
  .similar-apartments
  .price-and-availability
  .price
  .net-price,
.find-a-unit.detail
  .similar-apartments
  .price-and-availability
  .availability
  .net-availability {
  font-size: 16px;
  line-height: 22px;
  /* margin-bottom: 24px; */
  min-width: 120px;
}

.find-a-unit.detail .similar-apartments .price-and-availability .price .value,
.find-a-unit.detail
  .similar-apartments
  .price-and-availability
  .availability
  .value {
  margin-bottom: 40px;
}

.find-a-unit.detail .similar-apartments .btn.btn-outline-none {
  border-radius: 0;
  font-family: var(--bold-font);
  font-size: 17px;
  margin: 5px 0;
  padding: 0;
  color: var(--color-select-label);
}

.find-a-unit.detail .apply {
  margin-top: 96px;
  margin-bottom: 102px;
}

.search-unit .search-box {
  width: 14.2%;
}

/*---------------------------------------------- dropdown----------------------------------- */
.MuiPaper-root {
  box-shadow: none !important;
  border-radius: 0px !important;
  overflow: hidden !important;
}

.MuiMenuItem-root.Mui-selected {
  background-color: transparent !important;
}
.MuiMenuItem-root {
  font-family: Montserrat !important;
}

#menu-ameneties .Mui-selected {
  color: #007cba;
  font-weight: 400;
}

.MuiMenuItem-root .Mui-selected:hover {
  background-color: transparent !important;
}

.MuiMenuItem-root:hover {
  background-color: transparent !important;
}

.MuiMenuItem-root:nth-child(even):nth-child(even) {
  background-color: #f0f0f0 !important;
}

.MuiMenuItem-root {
  /* border: 1px solid #BBBBBB !important; */
  border-top: none !important;
  border-right-width: 0px !important;
  border-left-width: 0px !important;
}

/* .MuiMenuItem-root:active {
  background-color: pink !important;
} */
.MuiMenu-list {
  padding: 0 !important;
  border: 1px solid #bbbbbb !important;
  margin-top: 6px !important;
}

.MuiSelect-select {
  padding-right: 32px;
  padding-left: 15px !important;
}

/*---------------------------------------------- dropdown----------------------------------- */
/* MuiSelect-select MuiSelect-outlined MuiOutlinedInput-input MuiInputBase-input css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input */
.MuiOutlinedInput-root {
  margin: 0 !important;
}

.monthly-rent .MuiSlider-track {
  height: 3px;
}

.monthly-rent .MuiSlider-thumb {
  width: 15px;
  height: 15px;
  background-color: white;
  border: 1px solid #757575;
}

.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: 0;
}

.MuiOutlinedInput-root .MuiOutlinedInput-input {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  padding: 0 5px;
}

.contact-us .MuiOutlinedInput-root .MuiOutlinedInput-input {
  color: #000000;
  font-family: Montserrat;
  font-weight: normal;
  font-size: 18px;
  padding: 0;
}

.find-a-unit.detail .slide {
  padding: 10px;
  height: auto;
  max-height: 800px;
  cursor: pointer;
}

.find-a-unit.detail .slide img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 76vh;
  max-height: 613px;
}

.find-a-unit.detail .properties-card {
  margin-bottom: 1rem;
}

.property-description .property-description-title {
  font-family: var(--bold-font);
}

.property-description .property-description-content {
  font-family: var(--regular-font);
  line-height: 34px;
  margin-bottom: 0;
}

.download-link {
  color: var(--color-theme);
  text-decoration: none;
  display: inline-block;
  margin: 50px 0;
}

.download-link:hover,
.download-link:focus {
  text-decoration: none;
}

/* Properties */
.properties-detail .properties-card .propterties-card-body .location,
.properties-detail .properties-card .propterties-card-body .type {
  font-size: 12px;
}

.properties-card .propterties-card-body .sub-type {
  font-size: 16px;
  line-height: 22px;
}

.properties-detail .properties-card .propterties-card-body .address {
  margin-bottom: 20px;
}

.properties-detail .properties-card {
  margin-bottom: 0rem;
}

.properties .property-content {
  max-width: 948px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

.properties .property-content p {
  font-size: 16px;
  line-height: 28px;
  text-align: center;
}

.properties .property-content .feature-list li {
  font-size: 20px;
}

.properties .property-content .feature-title {
  font-size: 16px;
  margin-bottom: 15px;
}

.property-view {
  width: 100%;
  max-width: 100%;
  object-fit: contain;
  object-position: center;
}

/* .property-view-wrapper {
  height: 100vh;
  overflow: hidden;
  position: relative;
} */

.find-a-unit .scroll-view {
  /* margin-bottom:20px; */
  position: relative;
  overflow-y: scroll;
  max-height: 850px;
  width: -webkit-fill-available;
  max-width: 670px;
}

.find-a-unit .scroll-view::-webkit-scrollbar {
  width: 0;
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

.property-detail-box {
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  padding: 40px 70px;
  height: 180px;
  width: 948px;
  color: var(--color-white);
  font-size: 12px;
  line-height: 17px;
  font-family: var(--semibold-font);
}

.property-detail-box .property-city {
  /* margin-left:30px; */
  font-size: 14px;
  margin-bottom: 2rem;
  text-align: left;
}

.property-detail-box .property-address {
  font-size: 22px;
  color: var(--color-cyan);
  margin-bottom: 12px;
  text-transform: uppercase;
  /* text-align: center; */
}

.property-detail-box .property-price {
  font-size: 22px;
  margin-bottom: 15px;
}

.property-view {
  width: 100%;
  max-width: 100%;
  object-fit: contain;
  object-position: center;
}

.property-view-wrapper {
  height: auto;
  overflow: hidden;
  position: relative;
}

.property-detail-box {
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  padding: 40px 55px;
  height: 180px;
  width: 948px;
  color: var(--color-white);
  font-size: 12px;
  line-height: 17px;
  font-family: var(--semibold-font);
}

.margin-top {
  padding-top: 86px;
}

.margin-bottom {
  margin-bottom: 110px;
}

.properties .property-content h1 {
  text-align: left;
  line-height: 22px;
  letter-spacing: 0.2em;
  font-size: 16px;
  font-family: var(--regular-font);
  margin-bottom: 2.5rem;
  padding-left: 12px;
}

.properties .property-content h2 {
  text-align: center;
  font-size: 22px;
  font-family: var(--regular-font);
  margin-bottom: 2.5rem;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  line-height: 29px;
}

.properties .property-content p {
  font-size: 21px;
  font-family: var(--regular-font);
  margin-bottom: 25px;
  line-height: 26px;
  color: var(--color-black);
  text-align: center;
  /* align-items: center; */
}

.properties .property-content .feature-title {
  font-family: var(--semibold-font);
  font-size: 21px;
  text-transform: capitalize;
  text-align: left;
}

.properties .property-content .feature-title-floor {
  font-family: var(--bold-font);
  font-size: 21px;
  text-transform: capitalize;
  text-align: center;
}

.properties .property-content .first-title {
  text-align: left;
}

.properties .property-content p.floor-plans-content {
  margin-bottom: 36px;
}

.properties .property-content .feature-list {
  list-style-type: none;
  font-size: 20px;
  line-height: 27px;
  padding-left: 25px;
}

.properties .property-content .feature-list li {
  margin-bottom: 15px;
  color: var(--color-black);
  list-style-image: url(../images/checkmark.svg);
}

.properties .featured-caption-text {
  color: var(--color-white);
  margin: 0;
  font-size: 17px;
}

.properties .embed-responsive.map-view {
  height: 60vh !important;
}

.unit-image {
  height: 776px;
  max-height: 776px;
  width: 100%;
  object-fit: cover;
}

.unit-image-notAvailable{
  width: 100%;
  object-fit: cover;

}

@media (min-width: 401px) {
  .mobile-img {
    display: none;
  }

  .desktop-img {
    display: inline;
  }
}

@media (max-width: 575.98px) {
  .stats > div:last-child {
    grid-column: 1/-1;
    margin-top: 1.5rem;
  }
}

@media (max-width: 1333px) {
  .find-a-unit.detail .price-and-availability .availability {
    padding-left: 0px;
    padding-right: 1rem;
  }

  .find-a-unit.detail .similar-apartments .btn.btn-outline-none {
    font-size: 17px;
  }
}

@media (max-width: 992px) {
  .find-a-unit.detail .similar-apartments .custom-margin {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .find-a-unit.detail .property-content h2 {
    margin-top: 80px;
  }

  .find-a-unit .fixed-card {
    width: 100%;
    margin: 0;
  }

  .find-a-unit .properties-card {
    margin-bottom: 1rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .find-a-unit .margin {
    margin-bottom: 15px;
  }

  .find-a-unit .search-unit .monthly-rent {
    margin-top: 1px;
    padding: 0.375rem 0.75rem;
  }

  .find-a-unit .search-unit .col {
    width: 100%;
    -webkit-flex: auto;
    flex: auto;
    padding-left: 18px;
    padding-right: 18px;
  }
}

@media (min-width: 990px) {
  .properties .property-content h1 {
    text-align: center;
    margin-top: 2.5rem;
    font-size: 22px;
    line-height: 29px;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .page-heading {
    font-size: 40px;
  }

  .leadership-content {
    padding: 7.3rem 0;
    -webkit-column-gap: 44px;
    -moz-column-gap: 44px;
    column-gap: 44px;
    -ms-grid-columns: 1fr 44px 1fr;
    grid-template-columns: 1fr 1fr;
  }

  /* .leadership-content-container {
    max-width: 1178px;
  } */
  .user {
    gap: 16px;
  }

  .user-description {
    line-height: 1.25;
  }

  .user-details h6 {
    font-size: 22px;
  }

  .user-details strong {
    font-size: 16px;
  }

  .leadership-content {
    padding: 7.3rem 0;
    -webkit-column-gap: 44px;
    -moz-column-gap: 44px;
    column-gap: 44px;
    -ms-grid-columns: 1fr 44px 1fr;
    grid-template-columns: 1fr 1fr;
  }

  /* .leadership-content-container {
    max-width: 1178px;
  } */
  .user {
    gap: 16px;
  }

  .user-description {
    line-height: 1.25;
  }

  .user-details h6 {
    font-size: 22px;
  }

  .user-details strong {
    font-size: 16px;
  }
}

@media (min-width: 767px) and (max-width: 1230px) {
  .availability {
    padding-left: 80px;
  }

  .find-a-unit.detail .price-and-availability .availability {
    padding-left: 0px;
    padding-right: 0;
  }
}

@media screen and (max-width: 767px) {
  .properties .property-content h2 {
    font-size: 18px;
    line-height: 22px;
  }

  .availability {
    padding-left: 0px;
  }

  .find-a-unit.detail .price-and-availability .availability {
    padding-left: 0;
    padding-right: 0;
  }

  .find-a-unit.detail .slide img {
    margin-left: 6px;
    padding-right: 6px;
    width: 100%;
    height: 248px;
  }

  .h1,
  h1 {
    font-size: 2.1rem;
  }

  .h2,
  h2 {
    font-size: 1.8rem;
  }

  .faqs-section-top h6 {
    font-size: 16px;
    line-height: 20px;
  }

  .faq-ania-form .form-control {
    height: 52px;
    padding: 0 15px;
  }

  .faq-ania-form .btn {
    min-width: 100px;
  }

  .faq-ania-form .ania-form .btn {
    font-weight: 600 !important;
  }

  .faqs-category-buttons .d-flex {
    padding: 0 15px;
  }

  .faqs-category-buttons .d-flex button {
    width: calc(50% - 10px);
    margin: 0 5px 10px;
    padding: 20px 10px;
    font-size: 15px;
    line-height: 1.4;
  }

  .faqs-listin-head {
    padding: 2rem 0 1rem;
  }

  .allfaqs .card-header .btn {
    /* font-size: 16px; */
    padding: 15px 40px 14px 10px;
  }

  .allfaqs .card-header .btn::after {
    top: 20px;
    width: 18px;
    height: 18px;
  }

  .allfaqs .card-body {
    padding: 0 40px 20px 10px;
  }

  .allfaqs .card-body p {
    /* font-size: 15px; */
    line-height: 1.5;
  }

  .faqs-listing {
    padding: 0.7rem 0 5rem;
  }

  .page-content-section p {
    font-size: 16px;
    line-height: 24px;
  }

  .page-content-section h2 {
    font-size: 1.8rem;
    margin-bottom: 2rem;
  }

  .page-content-section h3 {
    margin: 2rem 0 0;
    font-size: 1.3rem;
  }

  .find-a-unit.detail .type {
    font-size: 38px;
    line-height: 46px;
  }

  .find-a-unit.detail .price-and-availability .price .net-price,
  .find-a-unit.detail .price-and-availability .availability .net-availability {
    font-size: 18px;
  }

  .find-a-unit.detail .price-and-availability .price .value,
  .find-a-unit.detail .price-and-availability .availability .value {
    font-size: 32px;
    line-height: 39px;
  }

  .address-font {
    font-size: 16px;
    line-height: 20px;
  }

  .mobile-center .btn {
    font-size: 16px;
    line-height: 20px;
    width: 188px;
    height: 45px;
  }
}

@media screen and (max-width: 992px) {
  .box-button {
    width: 250px;
  }

  .unit-image {
    height: auto;
    position: unset;
  }

  .search-unit .search-box {
    width: 100%;
  }

  .find-a-unit .scroll-view {
    margin-bottom: 16px;
    max-height: 720px;
  }

  .welcome {
    font-size: 5vw;
    /* top: 257px; */
  }

  .header .popup {
    width: 100%;
  }

  .services-block-wrapper {
    display: block;
    height: 100%;
    width: 100%;
    -webkit-transition: width 500ms;
    -o-transition: width 500ms;
    transition: width 500ms;
    padding: 0;
    margin-bottom: 25px;
  }

  .services-row-first:hover .services-block-wrapper,
  .services-row-second:hover .services-block-wrapper {
    width: 100%;
  }

  .services-row-first:hover .services-block-wrapper:hover,
  .services-row-second:hover .services-block-wrapper:hover {
    width: 100%;
  }

  .services-block-wrapper:first-child {
    padding-left: 0;
  }

  .services-block-wrapper:last-child {
    padding-right: 0;
  }

  .services-block-wrapper .services-learnmore {
    font-weight: 600;
    color: var(--color-btn-border);
    font-size: 1rem;
    -webkit-transition: ease 0.3s all;
    -o-transition: ease 0.3s all;
    transition: ease 0.3s all;
    text-decoration: none;
  }

  .ania-banner {
    padding: 0;
  }

  .ania-story-1 {
    position: relative;
    min-height: auto;
  }

  .ania-story {
    padding: 2rem 0rem;
  }

  .ania-services {
    padding: 2rem 0rem;
  }

  .ania-featured-properties {
    padding-bottom: 2rem;
  }

  .img-left {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
  }

  .img-right {
    width: 100%;
    height: auto;
    position: relative;
    border: 0px;
    margin-bottom: 10px;
  }

  .social-icons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    list-style-type: none;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .ania-footer .copyright {
    font-size: 14px;
    text-align: left;
    line-height: normal;
    margin-bottom: 0;
  }

  .content-right {
    position: relative;
    right: unset;
    top: unset;
    max-width: unset;
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-basic);
    width: 100%;
  }

  .section-title {
    font-size: 1.75rem;
  }

  .services-title {
    font-size: 24px;
    margin-left: 6px;
  }

  .services-description {
    font-size: 18px;
    min-height: unset;
  }

  .main-banner {
    overflow: hidden;
    position: relative;
    height: 70vh;
  }

  .img-main {
    max-width: 100%;
    height: 70vh;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .featured-caption,
  .featured-caption-text {
    opacity: 1;
  }

  .services-row-first,
  .services-row-second {
    display: block;
    width: 100%;
    height: 100%;
  }

  .featured-block {
    margin-bottom: 20px;
  }

  .content-right .section-title {
    font-size: 1.75rem;
    text-align: center;
    margin-bottom: 10px;
  }

  .properties .embed-responsive {
    height: 60vh;
  }

  .properties .properties-card {
    margin-bottom: 2rem;
  }

  .btn {
    padding: 0.5rem 3rem;
  }

  .ania-content .page-title {
    font-size: 32px;
  }

  .find-a-unit .properties-card {
    margin-bottom: 1rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .find-a-unit .embed-responsive {
    height: 100vh;
  }

  .last.last {
    margin-bottom: 0;
  }

  .ania-content .main-content {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .ania-quicklinks li a {
    font-size: 1rem;
  }

  .ania-footer .title {
    font-size: 1rem;
  }

  .ania-footer .email,
  .ania-footer .phone,
  .ania-footer .address {
    font-size: 1rem;
  }

  .ania-quicklinks {
    list-style-type: none;
  }

  .footer-copyright {
    padding: 0 -1rem 0rem 1.875rem;
    margin-top: 3.125rem;
  }

  .faqs-section-top h6 {
    font-size: 18px;
    line-height: 24px;
  }

  .faq-ania-form .form-control {
    padding: 0 15px;
    margin-bottom: 20px;
  }

  .faq-ania-form .btn {
    min-width: 100px;
    width: 100%;
  }

  .faqs-category-buttons .d-flex {
    padding: 0 15px;
  }

  .faqs-category-buttons .d-flex button {
    width: calc(50% - 10px);
    margin: 0 5px 10px;
    padding: 20px 10px;
    font-size: 15px;
    line-height: 1.4;
  }

  .faqs-listin-head {
    padding: 2rem 0 1rem;
  }

  .allfaqs .card-header .btn {
    /* font-size: 16px; */
    padding: 15px 40px 14px 10px;
  }

  .allfaqs .card-header .btn::after {
    top: 20px;
    width: 18px;
    height: 18px;
  }

  .allfaqs .card-body {
    padding: 0 40px 20px 10px;
  }

  .allfaqs .card-body p {
    line-height: 1.5;
  }

  .faqs-listing {
    padding: 0.7rem 0 5rem;
  }

  .ania-content.inquiry {
    padding-bottom: 2rem;
  }

  .find-a-unit .embed-responsive {
    height: 100vh;
  }

  .btn.btn-outline-none::after {
    position: relative;
    content: "";
    display: inline-block;
    width: 0.6em;
    height: 0.6em;
    border-right: 0.1em solid var(--color-select-label);
    border-top: 0.1em solid var(--color-select-label);
    transform: rotate(45deg);
    margin-right: 0.5em;
    left: 2px;
    top: -1px;
  }

  .find-a-unit .search-unit .search-option {
    padding: 14px 0px 0px;
  }

  .search-unit .col {
    width: 100%;
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    padding-left: 18px;
    padding-right: 18px;
  }

  .find-a-unit.detail .main-content {
    display: none;
  }

  .find-a-unit.detail .breadcrumb-nav {
    display: none;
  }

  .find-a-unit.detail .properties-card {
    margin-bottom: 1rem;
    margin-left: 15px;
    margin-right: 15px;
  }

  .find-a-unit.detail .featured-caption-text {
    color: var(--color-white);
    margin: 0;
    font-size: 14px;
  }

  .find-a-unit.detail .apply {
    margin-top: 58px;
    margin-bottom: 48px;
  }

  .find-a-unit.detail .property-content h2 {
    text-align: center;
    font-size: 18px;
    font-family: var(--regular-font);
    margin-bottom: 2.5rem;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    line-height: 28px;
    padding: 0 10px;
  }

  .find-a-unit.detail .margin-top {
    padding-top: 20px;
    margin-bottom: 20px;
  }

  .find-a-unit.detail .price-and-availability .price .net-price,
  .find-a-unit.detail .price-and-availability .availability .net-availability {
    font-size: 18px;
    margin-bottom: 24px;
    min-width: 150px;
  }

  .download-link {
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 40px;
  }

  .mobile-center {
    /* justify-content: center !important; */
    margin-top: -15px;
    margin-bottom: 25px;
    font-size: 20px;
  }

  .find-a-unit.detail .location {
    padding: 7px 0 15px;
  }

  .find-a-unit.detail
    .similar-apartments
    .properties-card
    .propterties-card-body
    .location
    .city {
    font-family: var(--bold-font);
    font-size: 16px;
  }

  .find-a-unit.detail
    .similar-apartments
    .properties-card
    .propterties-card-body
    .location
    .address {
    font-family: var(--regular-font);
    font-size: 16px;
  }

  .find-a-unit.detail .similar-apartments .type {
    font-family: var(--regular-font);
    font-size: 24px;
  }

  .find-a-unit.detail .price-and-availability {
    margin-top: 20px;
    padding-bottom: 0 !important;
  }

  .find-a-unit.detail .property-description {
    padding: 0 !important;
  }

  /* .find-a-unit .price-and-availability {
  border-bottom: 1px solid var(--color-select-border);
  padding-bottom: 19px;
} */
  .find-a-unit.detail
    .similar-apartments
    .price-and-availability
    .price
    .net-price,
  .find-a-unit.detail
    .similar-apartments
    .price-and-availability
    .availability
    .net-availability {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .find-a-unit.detail .similar-apartments .price-and-availability .price .value,
  .find-a-unit.detail
    .similar-apartments
    .price-and-availability
    .availability
    .value {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .find-a-unit.detail .similar-apartments .btn.btn-outline-none {
    font-size: 14.5px;
  }

  .find-a-unit.detail .slide {
    padding: 5px;
    height: auto;
    max-height: 800px;
    cursor: pointer;
  }

  .slick-slider {
    margin-left: -36px;
  }

  .properties-detail .properties-card {
    margin-bottom: 1.5rem;
  }

  .properties-detail .btn-outline-secondary {
    font-size: 16px;
    padding: 0.725rem 3rem;
  }

  .properties-detail .properties-card .propterties-card-body .location,
  .properties-detail .properties-card .propterties-card-body .type {
    font-size: 18px;
  }

  .properties-detail .properties-card .propterties-card-body .address {
    font-size: 26px;
  }

  .properties-detail .properties-card .propterties-card-body .sub-address {
    font-size: 26px;
  }

  .properties .embed-responsive.map-view {
    height: 100vh !important;
  }

  .properties .property-content p {
    font-size: 18px;
    line-height: 26px;
    padding-left: 12px;
    text-align: left;
  }

  .properties .property-content .feature-list {
    padding-left: 37px;
    line-height: 32px;
  }

  .properties .property-content .feature-list li {
    font-size: 18px;
  }

  .properties .property-content .feature-title {
    font-size: 18px;
    margin-bottom: 15px;
  }

  .properties .property-content .feature-title-floor {
    font-size: 18px;
    margin-bottom: 15px;
    margin-top: 15px;
  }

  .property-view {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    object-position: revert;
    height: 60vh;
  }

  .property-detail-box {
    background: rgba(0, 0, 0, 0.8);
    position: relative;
    padding: 30px 25px;
    height: auto;
    width: 100%;
    font-size: 12px;
    line-height: 14px;
  }

  .property-view-wrapper {
    height: auto;
    overflow: hidden;
    position: relative;
  }

  .property-detail-box .property-city {
    margin-left: 0px;
    font-size: 14px;
    margin-bottom: 2rem;
    text-align: center;
  }

  .property-detail-box .property-address {
    font-size: 18px;
    color: var(--color-cyan);
    margin-bottom: 12px;
    text-transform: uppercase;
    text-align: center;
  }

  .property-detail-box .property-price {
    font-size: 22px;
    margin-bottom: 15px;
  }

  .margin-bottom {
    margin-bottom: 53px;
  }

  .slick-slider {
    margin-left: -36px;
  }

  .property-description .property-description-title {
    font-family: var(--bold-font);
    font-size: 18px;
  }

  .property-description .property-description-content {
    font-family: var(--regular-font);
    line-height: 34px;
    margin-bottom: 0;
    font-size: 18px;
  }
}

.tag {
  position: absolute;
  top: 0px;
  right: 0px;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
  padding: 12px;
}

.now-renting {
  background: #037cba;
}

.coming-soon {
  background: #00b0b9;
}

.container-width {
  margin-right: auto;
  margin-left: auto;
  max-width: 1210px;
}

.terms-and-use ul {
  line-height: 26px;
  margin-left: 40px;
}
/* .properties .map-height {
  max-height: 1147px;
} */

.link {
  color: #6c757d;
}

.link:hover {
  color: #6c757d;
}

.footer-left-half {
  width: 60%;
}

@media only screen and (min-width: 200px) and (max-width: 990px) {
  .properties .embed-responsive {
    height: 55vh;
  }

  .properties-detail .embed-responsive {
    max-height: 658px;
    height: 75vh;
  }

  .container-width {
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
    max-width: 740px;
  }

  .footer-left-half {
    width: 70%;
  }

  .properties .map-height {
    max-height: 348px;
  }

  .find-a-unit.detail .price-and-availability .price .value,
  .find-a-unit.detail .price-and-availability .availability .value {
    font-size: 30px;
    line-height: 37px;
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 520px) and (max-width: 992px) {
  .location {
    padding: 6.79px 0 5.77px;
    line-height: 19px;
  }

  .find-a-unit .properties-card .propterties-card-body .location .city,
  .find-a-unit .properties-card .propterties-card-body .location .address {
    font-size: 14px;
    line-height: 19px;
  }

  .find-a-unit .type {
    font-size: 26px;
    margin-bottom: 10px;
    line-height: 19px;
  }

  .find-a-unit .price-and-availability .price .net-price,
  .find-a-unit .price-and-availability .availability .net-availability {
    font-size: 14px;
    margin-bottom: 10px;
    line-height: 19px;
  }

  .find-a-unit .price-and-availability .price .value,
  .find-a-unit .price-and-availability .availability .value {
    font-size: 14px;
    margin-bottom: 10px;
    line-height: 19px;
  }

  .btn.btn-outline-none {
    font-size: 12.5px;
    margin: 0px -4px;
    line-height: 19px;
  }

  .find-a-unit .price-and-availability {
    padding-bottom: 10px;
  }
}

@media only screen and (min-width: 200px) and (max-width: 520px) {
  .find-a-unit .price-and-availability {
    padding-bottom: 10px;
  }

  .find-a-unit .properties-card .propterties-card-body .location .city,
  .find-a-unit .properties-card .propterties-card-body .location .address {
    font-size: 12px;
    line-height: 13px;
  }

  .location {
    padding: 6.79px 0 5.77px;
    line-height: 13px;
  }

  .find-a-unit .type {
    font-size: 15px;
    margin-bottom: 8px;
    line-height: 18px;
  }

  .find-a-unit .unit-card-img {
    height: auto;
    max-height: 135px;
  }

  .find-a-unit .price-and-availability .price .net-price,
  .find-a-unit .price-and-availability .availability .net-availability {
    font-size: 12px;
    margin-bottom: 8px;
    line-height: 13px;
  }

  .find-a-unit .price-and-availability .price .value,
  .find-a-unit .price-and-availability .availability .value {
    font-size: 12px;
    margin-bottom: 8px;
    line-height: 13px;
  }

  .btn.btn-outline-none {
    font-size: 11px;
    margin: 2px -19px;
    line-height: 13px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1439px) {
  .container-width {
    margin-right: auto;
    margin-left: auto;
    max-width: 1100px;
  }

  .properties .embed-responsive {
    height: 95vh;
  }

  .properties-detail .embed-responsive {
    max-height: 658px;
    height: 100vh;
  }
}

@media only screen and (min-width: 990px) and (max-width: 1200px) {
  .container-width {
    max-width: 910px;
    margin-right: auto;
    margin-left: auto;
  }

  .properties .embed-responsive {
    height: 75vh;
  }

  .properties-detail .embed-responsive {
    max-height: 658px;
    height: 75vh;
  }

  /* .footer-left-half {
    width: 70%;
   } */

  .content-right {
    position: relative;
    right: unset;
    top: unset;
    max-width: unset;
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-basic);
    width: 100%;
  }

  .content-right .section-title {
    font-size: 1.75rem;
    text-align: center;
    margin-bottom: 10px;
  }

  .img-right {
    position: relative;
    border: 0;
    margin-bottom: 10px;
  }

  .img-left,
  .img-right {
    width: 100%;
    height: auto;
  }
}

.properties .propterties-card-body p {
  padding-left: 0;
}

@media (max-width: 1168px) {
  .find-a-unit.detail .margin-top {
    padding-top: 30px;
    padding-bottom: 21px;
  }

  .find-a-unit.detail .type {
    font-size: 36px;
  }
}

@media (min-width: 1168px) and (max-width: 1205px) {
  .find-a-unit.detail .margin-top {
    padding-top: 7px;
    padding-bottom: 21px;
  }
}

@media (max-width: 767px) {
  .ania-footer .title {
    margin-top: 3.125rem;
  }

  .img-main {
    height: 413px;
  }

  .main-banner {
    height: 413px;
  }
}

.properties .properties-card .properties-card-tag {
  position: "absolute";
  top: "8px";
  right: "16px";
}

.propterties-card-body .location span {
  font-size: 18px;
}

.propterties-card-body .sub-location span {
  font-size: 14px;
  letter-spacing: 0.1em;
  line-height: 17px;
}

.propterties-card-body button {
  color: #636466;
}
.no-data-found{
  font-size: 30px;
  text-align: center;
  margin: 50px;
}
.properties .properties-card .propterties-card-body .address-detail {
  font-size: 18px;
}

@media (max-width: 539px) {
  .properties-card-body .location span {
    font-size: 9px;
  }

  .properties-card-body .sub-location span {
    font-size: 12px;
    line-height: 15px;
  }

  .properties .feature-title {
    text-align: left;
  }

  .properties .properties-card .propterties-card-body .address {
    font-size: 23px;
  }

  .properties-detail .properties-card .propterties-card-body .sub-address {
    font-size: 26px;
    line-height: 32px;
  }

  .properties .properties-card .propterties-card-body .address-detail {
    font-size: 16px;
  }

  .propterties-card-body button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 105px;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    color: #636466;
  }

  .tag {
    position: absolute;
    top: 0px;
    right: 0px;
    font-weight: bold;
    font-size: 12px;
    color: #ffffff;
    padding: 8px;
  }

  .properties .map-title {
    font-size: 18px;
  }

  .main-content .properties {
    font-size: 32px !important;
  }

  .main-content .faq {
    font-size: 32px !important;
  }
}

.grecaptcha-badge {
  display: none;
}

/* hover_room */
.selection_room {
  display: flex;
  margin: -16px 95px;
}

.google-map-marker-body {
  width: 130px;
}
.gmap-marker-name {
  margin: 0 5px;
}
.google-map-marker-tooltip {
  font-size: 10px;
  background-color: white;
  color: #333333;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main_room {
  width: 140px;
  height: 64px;
  background: #fff;
  padding: 7px;
  display: flex;
  box-shadow: 1px 1px 2px #757575;
}

.main_room .room_details {
  display: inline;
}

.main_room .room {
  margin-right: 7px;
  display: inline;
  object-fit: cover;
  object-position: center;
  width: 50px;
}

.main_room .room_details p {
  margin-top: 7px;
  margin-bottom: 2px;
  padding: 0;
}
.youtube-embed-url {
  padding-bottom: 80px;
}
