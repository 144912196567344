.career-label {
  background-color: #9d9d9d;
  color: white;
  padding: 0.5rem;
  cursor: pointer;
  height: 60px;
  width: 360px;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  border: 1px solid #9d9d9d;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.career-content {
  margin-bottom: 50px;
  line-height: 20px;
}
/* @media (max-width: 800px) {
  .label {
    width: 100%;
  }
} */
#file-chosen {
  margin-bottom: 12px;
}
.upload-main {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-transform: capitalize;
  min-width: 300px;
}

.career-label label{
  margin: 0;
}